import { gradientParser } from "@gqlapp/base/colorful/utils/gradientParser";
import { ErrorCorrectLevel } from "@gqlapp/qrcode/qrcode";


export default function QrValueMap(values) {
  let gradient, { dataStroke, backgroundColor, image, imageIsBg, paramValue, selectedIndex } = values || {}
  let { isCircle, correctLevel, eyeCustom, icon, frame, eyeShape, eyeInner, color, data_text, text } = values || {}
  let length = `${data_text}`.length;
  
  if(typeof correctLevel === 'undefined' && typeof data_text !=='undefined'){
    if(length < 40){
      correctLevel = ErrorCorrectLevel.H
    }else{
      correctLevel = ErrorCorrectLevel.Q
    }
  }
  
  if(color?.includes('gradient')){
    let type = color?.includes('radial') ? 1: 0;
    let { orientation, colorStops } = gradientParser(color);
    let values = colorStops.map(
      ({ value, left })=>{
        left = left || 0;
        left = (typeof left === 'undefined') ? 0: left;
        left = left * 100;
        return ({ left, value })
      }
    )
    gradient = { values, type, degreeStr: orientation?.value || 90 , enabled: 1 }
  }
  
  
  return Object.assign(values || {},
    {
      data_text, correctLevel,
      isCircle: isCircle || true,
      image: image || 0,
      imageIsBg: imageIsBg || false,
      paramValue: paramValue || [],
      selectedIndex: selectedIndex || 0,
      color: color || '#000000',
      eyeCustom: !!eyeCustom,
      gradient: gradient || { enabled: 0 },
      dataStroke: dataStroke || '#ffffff50',
      backgroundColor: backgroundColor || '#ffffff',
    },
    { frame: {
        text: text || `qr:text`.t(), icon: 0, font: 0,
        style: frame?.style|| 25, color: '#000000', iconColor: '#000', ...frame }},
    { icon: { ...icon,
        enabled: !!(icon && icon.src) ? 1 : 0,
        src: icon && icon.src ? icon.src : null,
        scale: 33, bg: 0, size: 0 }},
    { eyeShape: eyeShape || {
        top_left: { type: 0, color: '#000000' },
        bottom_left: { type: 0, color: '#000000' },
        top_right: { type: 0, color: '#000000' }}},
    { eyeInner: eyeInner || {
        top_left: { type: 0, color: '#000000' },
        bottom_left: { type: 0, color: '#000000' },
        top_right: { type: 0, color: '#000000' }}}
  );
}
