import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";

/**
 * @QrProvider(
 *   id = "dot",
 *   title = @Translation("Dot")
 * )
 */
export class RendererDot extends PointBase {
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r"], []);
			return <circle {...$opts} />;
		}
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 75
		})
		
		debug('RendererDot', { $options })
		
		super($options, $qrcode);
	}
}
