import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";


/**
 * @QrProvider(
 *   id = "star",
 *   title = @Translation("Star")
 * )
 */
export class RendererStar extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "x", "y", "scale"]);
			let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
			$scale = $scale * 1.2;
			delete $opts['x'];
			delete $opts['y'];
			delete $opts['scale'];
			$opts = {...$opts,
				"transform": `translate(${$x},${$y}) scale(${$scale})`
			}

			return <g {...$opts}><path fillRule="evenodd" clipRule="evenodd" d="M49.984,99.824C45.848,69.47,28.497,53.961-0.345,49.773  c29.054-3.697,45.791-19.659,50.348-49.949c4.404,29.487,20.907,45.691,49.652,49.975C70.729,54.051,54.532,70.407,49.984,99.824z"/></g>;
		}
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 100,
		})
		
		debug('RendererStar', { $options })
		
		super($options, $qrcode);
	}
}
