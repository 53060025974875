import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions} from "./ultil";


/**
 * @QrProvider(
 *   id = "star_2",
 *   title = @Translation("Star 2")
 * )
 */
export class RendererStar2 extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", 'transform'], [], function ($opts: any){
				let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
				$scale = $scale * 16.5;
				return Object.assign($opts, { "transform": `translate(${$x},${$y}) scale(${$scale})` })
			});
			
			return <g {...$opts}><path d="M3.2,0.3l0.6,1.3C4,1.8,4.1,1.9,4.3,1.9l1.4,0.2c0.2,0,0.3,0.3,0.2,0.5l-1,1C4.7,3.7,4.7,3.9,4.7,4.1L5,5.5 c0,0.2-0.2,0.4-0.4,0.3L3.3,5.2c-0.2-0.1-0.4-0.1-0.6,0L1.4,5.8C1.2,5.9,1,5.8,1,5.5l0.2-1.4c0-0.2,0-0.4-0.2-0.5l-1-1 C-0.1,2.4,0,2.2,0.2,2.1l1.4-0.2c0.2,0,0.4-0.2,0.5-0.3l0.6-1.3C2.9,0.1,3.1,0.1,3.2,0.3z" /></g>;
		}
		
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 80,
		})
		
		debug('RendererStar2', { $options })
		
		super($options, $qrcode);
	}
}
