import { QRPointType } from '../qrcode'
import Base from "./Base";
import { getTypeTable, pointCallback } from "./ultil";
import React, {Fragment} from 'react';
/**
 * @QrProvider(
 *   id = "mosaic",
 *   title = @Translation("Mosaic")
 * )
 */
export class RendererMosaic extends Base {
	/**
	 *
	 * @param $options
	 */
	public listPoints($options = {}){
		if (!this.$qrcode) return [];
		
		let { "isRotation" : $isRotation, "params" : $params, "out" : $out }: any = $options || {};
		let { "size" : $size, "opacity" : $opacity, "otherColor" : $otherColor, "width" : $width }: any = $params || {};
		
		let $nCount = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);

		let $pointList: any[] = [];


		$size = ($size || 100) / 100;
		$opacity = ($opacity || 100) / 100;
		$otherColor = $otherColor || 'black';
		if ($size <= 0) $size = 1;


		let $scale = $width * ($size/100);
		$scale = $scale || 1;

		pointCallback(this.$qrcode, $nCount, $out, ($i: number, $j: number, $x: number, $y: number) => {
			if (this.isEyes($typeTable, $x, $y)) return;
			if (!this.$qrcode.isDark($x, $y)) return;
			
			let $posX 	= $width * (($isRotation? ($nCount - 1 - $i):$i + (1 - $size)/2));
			let $posY 	= $width * ($j + (1 - $size)/2);
			// let $nscale = $scale / 5;

			let $rest =  {
				"opacity" 	: $opacity,
				"fill"			: $otherColor,
				"transform" : `translate(${$posX},${$posY}) scale(${$scale})`
			};

			try{
				let $rs = {  "i" : $x, "j" : $y, "count" : $nCount, "xOffset" : 0, "yOffset" : 0 };

				let $leftNeighbor 	= +this.getNeighbor({ ...$rs, "xOffset" : $isRotation ? 1: -1});
				let $rightNeighbor 	= +this.getNeighbor({ ...$rs, "xOffset" : $isRotation ? -1: 1});
				let $topNeighbor 	  = +this.getNeighbor({ ...$rs, "yOffset" : -1});
				let $bottomNeighbor = +this.getNeighbor({ ...$rs, "yOffset" : 1});

				let $neighborsCount = $leftNeighbor + $rightNeighbor + $topNeighbor + $bottomNeighbor;

				switch ($neighborsCount){
					case 0:
						$pointList.push(<g {...$rest}><polygon points='0,15 85,0 100,85 15,100'/></g>);
						break;
					case 1:
						let $data = "15,5 5,85 85,95 95,15";

						if ($topNeighbor) {
							$data = "0,10 90,0 100,90 10,100";
						} else if ($rightNeighbor) {
							$data = "5,25 75,5 95,75 25,95";
						} else if ($bottomNeighbor) {
							$data = "0,5 95,0 100,95 5,100";
						}

						$pointList.push(<g {...$rest}><polygon points={$data} /></g>);
						break;
					case 2:
						let $points = "8,4 4,92 92,96 96,8";
						if ($leftNeighbor && $topNeighbor) {
							$points = "0,17 83,0 100,83 17,100";
						}
						else if ($topNeighbor && $rightNeighbor) {
							$points = "13,0 0,87 87,100 100,13";
						}
						else if ($rightNeighbor && $bottomNeighbor) {
							$points = "0,12 88,0 100,88 12,100";
						}
						$pointList.push(<g {...$rest}><polygon points={$points} /></g>);
						break;
					default:
						if ($neighborsCount > 2 || ($leftNeighbor && $rightNeighbor) || ($topNeighbor && $bottomNeighbor)) {
							$pointList.push(<g {...$rest}><polygon points='8,4 4,92 92,96 96,8'/></g>);
						}
				}
			}catch (e){}

		});

		return $pointList.map((e, key)=> <Fragment key={key}>{e}</Fragment>);
	}
}
