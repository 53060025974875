import React, { Fragment } from "react";
import Base from './Base';
import { getTypeTable, pointCallback } from "./ultil";

export class PointBase extends Base {
	/**
	 *
	 * @param $options
	 */
	public listPoints($options: any = {}): any[]{
		if (!this.$qrcode) return [];
		
		let { "out" : $out, "pos" : $pos, "dataStroke" : $dataStroke } = $options || {};
		$dataStroke = $dataStroke || '#ffffff';
		
		$pos = $pos || (()=>{})
		let $pointList: any[] = [];
		let $nCount: number = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);
		let $dataOpts: any = this.getDataOptions($nCount, $options);
		
		pointCallback(this.$qrcode, $nCount, $out, ($xp: number, $yp: number, $x: number, $y: number) => {
			if (this.isEyes($typeTable, $x, $y)) return;
			if (this.$qrcode.isDark($x, $y)) return;
			if (!$options?.imageIsBg) return;
			$pointList.push($pos(Object.assign({},
				$dataOpts,
				{ "x": $xp, "y": $yp},
				{ "fill" 		: $dataStroke }
			)));
		})
		
		pointCallback(this.$qrcode, $nCount, $out, ($xp: number, $yp: number, $x: number, $y: number) => {
			if (this.isEyes($typeTable, $x, $y)) return;
			if (!this.$qrcode.isDark($x, $y)) return;
			$pointList.push($pos(Object.assign({},
				$dataOpts,
				{ "x": $xp, "y": $yp}
			)));
		});
		
		return $pointList.map((e, key)=> <Fragment key={key}>{e}</Fragment>);
	}
}

export default PointBase;
