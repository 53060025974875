import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";
/**
 * @QrProvider(
 *  id = "round_in_smooth",
 *  title = @Translation("RoundInSmooth"),
 * 	type = 12
 * )
 */
export class RendererRoundInSmooth extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererRoundInSmooth', {$options})
		super({...$options, type: 12 }, $qrcode)
	}
}
