import React from "react";
import ClientModule from '@gqlapp/module-client-react';
import resources from './locales';
import Popup from './helpers/popup';
import Route from "@xem/router/classes/Route";
import VietBank from "./containers/Vietbank";
import { map, defaultRoute as VB } from "./helpers/config";
import getProps from "@xem/entity/classes/getProps";
import resolvers, { defaults } from '@xem/qr-common/resolvers';
export default new ClientModule(Popup, {
	resolver: [{ resolvers: resolvers(), defaults}],
	localization: [{ ns: 'qr', resources, more: ['common'] }],
	route:{
		VietBank      : <Route {...getProps(map, VB, VietBank, { unloginedClass: '', runtimejs: true, style:{ '--page-header-height': '0px !important' }}, 'path')} />
	},
});
