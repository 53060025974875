import React, { Fragment } from "react";
import clsx from 'clsx';

import Box from "@mui/material/Box";
import Top from "@xem/icon/base/Top";
import Button from "reactstrap/lib/Button";
import Dropzone from "@xem/look/Cropping/UploadButton";
import WidgetBox from "@gqlapp/base/View/WidgetBox";

import Table from "@gqlapp/base/Table/Grid";
import Horizontal from "@gqlapp/base/Row/Horizontal";
import RenderSwitch from "@gqlapp/base/RenderSwitch";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";

import template, {getColumns} from "./template";

const BaseForm = template((props) => {
	let { values, datasource, setFieldValue, onChange } = props;
	let { sx, disableUploadIcon, setScrollTop, zoomin } = props;

	let columns = getColumns(props);

	let btnProgs = {
		type:'button',
		size:'sm',
		style:{ minWidth: '150px'},
		color:'orange',
		className:'text-white text-uppercase',
	}
	
	Object.assign(sx, {
		'.image-is-bg':{
			'.MuiFormControlLabel-label':{
				paddingLeft: '5px !important'
			}
		}
	});
	
	return (<>
		<Box sx={sx}>
			<Button
				onClick={() => setScrollTop(true)}
				color={'default'} className={clsx('p-0', 'circle-up', {'in': zoomin})}
				style={{ position: 'fixed', bottom: '60px', right: '2px', zIndex: 10 }}>
				<Top/>
			</Button>
			<WidgetBox
				title={<>
					<Row medium>
						{ (!disableUploadIcon && Dropzone) &&<Col>
							<Dropzone
								{...{ accept: {'image/*': []}}}
								{...{ name: 'image', multiple: false }}
								{...{
									style: { height: 'auto'},
									callback: (name, files, type = '') =>{
										let file = (files || []).shift();
										return func(props?.callback)({ file, type })
									}
								}}
							>
								<Button
									{...btnProgs}
								>{'qr:logo.upload'.t()}</Button>
							</Dropzone>
						</Col>}
						<Col>
							<Field
								{...{
									className: 'image-is-bg MuiSwitch-custom',
									component: RenderSwitch,
									onChange,
									label: 'qr:image.isBg'.t(),
									name: 'imageIsBg',
									value: values?.imageIsBg
								}}
							/>
						</Col>
					</Row>
					
				</>}
				{...{
					color: 'none',
					className: 'mb-0',
					classNameTitle: clsx('d-flex justify-content-center', 'border-0 mb-0 py-1 px-3 bg-white'),
					classNameBody:'bg-white pt-2 px-3'
				}}
			>
				<Box className={'container-logo'}>
					<Table
						none
						size={50}
						minSpace={5}
						tag={Horizontal}
						columns={columns}
						datasource={datasource}
					/>
				</Box>
			</WidgetBox>
		</Box>
	</>)
})


export default BaseForm;