/* tslint:disable */
import QrValueMap from '@xem/qr-common/classes/QrValueMap';


export class Base{
  public fetchMore: any = null;
  public subscribeToMore: any = null;

  public id: number | undefined;
  public uid: number | undefined;
  public origin: string | undefined;
  public locked: boolean | undefined;

  public universal: string | undefined;
  public visitor: string | undefined;


  private _IOS: any;
  private _ANDROID: any;

  public advanced: any;

  public display: string | undefined;

  public qrurl: string   = ''
  public redirect = false;
  public author = '';


  private _protected: any;

  private _domain: string | boolean | undefined;

  public note = '';
  private _site: string | undefined;
  private _title: string | undefined;
  private _full: string | undefined;
  private _url: string | undefined;
  private _utm: any;
  private _metatags: any;
  private _category: any[] | undefined;
  private _assigned: any[] | undefined;
  private _analytics: any;
  private _location: any;
  public views = 0;

  private _DEEPLINK: object = {};
  public customstore: object = {}
  public created: string = null;
  public changed: string = null;

  private _type: string | undefined;
  private _images: any[] | undefined;
  public platform = 'web';
  private _qr: any;

  public image_type = null;
  public image_url = null;

  public refetch: any = null;
  public access: any;
  
  static instanse(data?: any) {
    data = data || {};
    return new this().save(data || {});
  }

  set qr(values: any) {
    // @ts-ignore
    this._qr = QrValueMap(values);
  }

  get qr() {
    return this._qr;
  }



  /**
   * get access
   * @protected
   */
  get utm() {
    return this._utm;
  }


  // @ts-ignore
  set info(values) {
    const { id, uid, platform, note, category, assigned, url, full, site, origin, universal }: any = values || {};
    const { title, views, domain, author, display, metatags, created, changed }: any = values || {};
    const { location, analytics, redirect, visitor, protected: _protected, advanced }: any = values || {};
    const { deeplink, type, qrurl, utm, customstore } = values || {};

    const { images, image_type, image_url} = values || {};

    const $self = this;

    Object.assign($self, {
      advanced: advanced || {},
      protected: _protected || {},

      id: id || 0,
      uid: uid || 0,
      origin: origin || '',
      universal: universal || '',
      visitor: visitor || '',
      analytics: analytics || {},
      display: display || 'origin',
    });

    this._title   = title;
    this._type    = type;

    this._domain  = domain;

    this._images      = images;
    this.image_type   = image_type;
    this.image_url    = image_url;

    this._utm   = utm || {};
    this.qrurl  = qrurl;
    this.author = author;
    this.views  = views || 0;
    this.note   = note || '';

    this._url = url;
    this.created = created;
    this.changed = changed;
    this.redirect = !!redirect;

    this.platform = platform;
    this.customstore = customstore;
    this.deeplink = deeplink;
    this.assigned = assigned;
    this.category = category;
    this.location = location;
    this.metatags = metatags;
    this.site = site;
    this.full = full;
    // this.ios      = ios;
    // this.android  = android;
  }

  /**
   * Save data
   * @param values
   */
  public save(values: any) {
    this.qr = values?.qr;
    this.access = values?.access;
    this.info = values;
    // @ts-ignore
    return this;
  }

  /**
   * {@inheritdoc}
   */
  get type() {
    return this._type || 'link';
  }

  /**
   * {@inheritdoc}
   */
  get domain() {
    return this._domain || false;
  }
  /**
   * {@inheritdoc}
   */
  set domain(values) {
    this._domain = values;
  }

  /**
   * {@inheritdoc}
   */
  set ios(value) {
    this._IOS = value;
  }
  get ios() {
    return this._IOS || null;
  }
  get store_url() {
    return this._IOS && this._IOS.value ? this._IOS.value : null;
  }

  get gplay_url() {
    return this._ANDROID && this._ANDROID.value ? this._ANDROID.value : null;
  }

  /**
   * @param value
   */
  set android(value) {
    this._ANDROID = value;
  }

  get android() {
    return this._ANDROID || null;
  }

  /**
   * {@inheritdoc}
   */
  set deeplink(value) {
    this._DEEPLINK = value;
  }

  get deeplink() {
    return this._DEEPLINK || {};
  }

  /**
   *
   * @param value
   */
  set protected(value: any) {
    this._protected = value;
  }

  get protected() {
    return this._protected || {};
  }

  /**
   *
   * @param value
   */
  set location(value: any) {
    this._location = value;
  }

  get location() {
    return this._location || {};
  }

  /**
   * {@inheritdoc}
   */
  get ips() {
    const _IPS: number[] = [];
    const { page, users, new: new_viewss } = this.location;

    const addIP = (ip: any) => {
      ip = Number(ip);
      if (!_IPS.includes(ip)) {
        _IPS.push(ip);
      }
    };

    if (page && page.length) {
      page.map(({ ip }: { ip: number }) => addIP(ip));
    }

    if (users && users.length) {
      users.map(({ ip }: { ip: number }) => addIP(ip));
    }

    if (new_viewss && new_viewss.length) {
      new_viewss.map(({ ip }: { ip: number }) => addIP(ip));
    }

    return _IPS;
  }

  /**
   * @param value
   */
  set analytics(value: any) {
    this._analytics = value;
  }
  get analytics() {
    return this._analytics || {};
  }

  /**
   *
   * @param value
   */
  set category(value: any[]) {
    this._category = value || [];
  }
  get category() {
    // @ts-ignore
    return this._category;
  }

  /**
   *
   * @param value
   */
  set assigned(value: any[]) {
    this._assigned = value;
  }
  get assigned() {
    return this._assigned || [];
  }

  /**
   * get site
   */
  get site() {
    return this._site || '';
  }
  set site(value: string) {
    this._site = value;
  }

  /**
   * get site
   */
  get url() {
    return this._url || '';
  }

  set url(value: string) {
    this._url = value;
  }

  /**
   * Get full path
   */
  get full() {
    return this._full || '';
  }

  set full(value) {
    this._full = value;
  }
  /**
   * get title
   */
  get og_title() {
    return this.value('title');
  }
  get og_description() {
    return this.value('description');
  }

  get og_image() {
    return this.value('og:image');
  }

  /**
   * set metatags
   * @param value
   */
  set metatags(value) {
    this._metatags = value;
  }

  get metatags() {
    return this._metatags || [];
  }

  /**
   * get metatags
   */
  get images() {
    const image = this.value('og:image');
    let images = this._images;

    if(!images){
      images = [{ url: null, thumb: { url: image }, big: { url: image } }]
    }

    return (images || []).map(
      ({ url, big, thumb, ...values }) => {
        big = big || { url: url || null };
        thumb = thumb || { url: url || null };
        return { ...values, url, big, thumb };
      }
    );
  }

  /**
   * get metatags
   */
  get image() {
    return [...this.images].shift() || { thumb: { url: null }, big: { url: null } };
  }


  /**
   * get metatags
   */
  get title() {
    return this.value('title') || this._title;
  }

  /**
   * get metatags
   */
  get description() {
    return this.value('description');
  }
  /**
   * get metatags
   */
  public value($key: string) {
    let _value = this.metatags.filter(({ key }: { key: string }) => key == $key).shift();
    try {
      _value = _value.value;
    } catch (e) {}
    return _value;
  }

}

export default Base;
