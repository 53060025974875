export const defaults = {};

// @ts-ignore
export default (rest?:any) => ({
	Mutation: {
		getQrcodeIcon: async ({}: any, { url }: any, __: any) =>{
			const imageUrlToBase64 = async (url: string) => {
				const data = await fetch(url);
				const blob = await data.blob();
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						const base64data = reader.result;
						resolve(base64data);
					};
					reader.onerror = reject;
				});
			};
			
			return await imageUrlToBase64(url)
		}
	}
});
