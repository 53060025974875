import { QRCode } from '../qrcode'
import LineBase from "./LineBase";

/**
 * @QrProvider(
 *   id = "line_2",
 *   title = @Translation("Line 2"),
 *   type = 2
 * )
 */
export class RendererLine2 extends LineBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererLine2', {$options})
		super({...$options, type: 2 }, $qrcode)
	}
}
