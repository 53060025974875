import React from 'react';
import compose from './Util';
import Base from "@xem/qr-client-react/components/QrView/QrView";

export default compose((props: any) => {
	let { qrcode, data_text } = props;
	return (<div>
		<Base
			{...{ values: {...qrcode, data_text }}}
			{...{ styleWrap: ({renderer}) => renderer }}
			{...{ viewStyle: { padding: '15px', height: '230px' }}}
		/>
	</div>);
});