import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "pointed_edge_cut",
 *   title = @Translation("Pointed edge cut"),
 *   type = 6
 * )
 */
export class RendererPointedEdgeCut extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererPointedEdgeCut', {$options})
		super({...$options, type: 6 }, $qrcode)
	}
}
