import Info from "./form/Info";

import InfoIcon from './form/Info.Icon.svg';

export const options = [
	{
		icon: InfoIcon,
		label: 'Info',
		value: 'info',
		render: Info
	}
]

export default options.map(({ render, ...rest})=> ({...rest, Form: render }));