import React, { Fragment } from 'react';
import autoSize from "@gqlapp/base/autoSize";
import { mapValues, eyeRenders, getTypeTable, getExactValue, getIdNum, Circle } from "./ultil";

import { QRCode, QRPointType } from '../qrcode';
import dataEyes from './eyes';
import dataShape from './shape';
import Frame from './frames';

// tslint:disable-next-line:no-padding

export abstract class Base {
	public $options: any = {
		size : 100,
		opacity : 100,
		posType : 0,
		otherColor : '#000000',
		render : "canvas",
		width : 256,
		height : 256,
		background : "#ffffff",
		foreground : "#000000"
	};

	$coordinates = {
		90: [0, 50, 100, 50],
		135: [0, 0, 100, 100],
		180: [50, 0, 50, 100],
		225: [100, 0, 0, 100],
		270: [100, 50, 0, 50],
		360: [50, 100, 50, 0],

	};
	/**
	 * @var int|mixed
	 */
	protected $type = 0;

	/**
	 * @var QRCode
	 */
	$qrcode: any;
	
	
	/**
	 * @var QRCode
	 */
	$autoSize: any;
	
	/**
	 * @param $options
	 * @param $qrcode
	 */
	constructor($options: any = {}, $qrcode: QRCode) {
		
		this.$options = mapValues(Object.assign(this.$options, $options)) || {};
		
		const { "typeNumber" : $typeNumber, "correctLevel" : $correctLevel, "data_text" : $text } = this.$options;
		if(!$qrcode){
			$qrcode = new QRCode()
		}
		if ($qrcode) {
			$qrcode.setTypeNumber($typeNumber);
			$qrcode.setErrorCorrectLevel($correctLevel);
			$qrcode.addData($text);
			$qrcode.make();
		}
		this.$qrcode = $qrcode;
		this.$type = $options.type || 0;
		this.$autoSize = autoSize(['28:28', '80:23', '100:20.7', '100:100']);
	}

	/**
	 *
	 * @param $options
	 */
	getNeighbor($options:any = {}) {
		const { "i" : $i, "j" : $j, "count" : $count, "xOffset" : $xOffset, "yOffset" : $yOffset }: any = $options || {};
		if ($i + $xOffset < 0 || $j + $yOffset < 0 || $i + $xOffset >= $count || $j + $yOffset >= $count) return false;
		return !!this.$qrcode && this.$qrcode.isDark($i + $xOffset, $j + $yOffset);
	}

	/**
	 *
	 * @param $width
	 * @param $out
	 */
	getViewBox($width = 0, $out = 0) {
		if (!this.$qrcode) return '0 0 100 100';
		$width = $width || 1;
		const $nCount = this.$qrcode.getModuleCount();
		const $res = [];
		$res.push(-$out * $width);
		$res.push(-$out * $width);
		$res.push(($nCount + $out * 2) * $width);
		$res.push(($nCount + $out * 2) * $width);
		return $res.join(" ");
	}

	getDataOptions($nCount: any, $options: any){
		let { "size" : $size, "stroke": $stroke, "strokeWidth": $strokeWidth  } = $options || {};
		let { "isRotation": $isRotation, "params" : $params, "imageIsBg": $imageIsBg  } = $options || {};
		let { "opacity" : $opacity, "otherColor" : $otherColor, "width" : $width } = $params || {};
		
		$size = ($size || 100) / 100;
		if ($size <= 0) $size = 1.0;
		
		$opacity = ($opacity || 100) / 100;
		$otherColor = $otherColor || 'black';
		let $scale = $width * ($size/100);
		$scale = $scale || 1;
		
		return ({
			"stroke"        : $stroke,
			"strokeWidth"   : $strokeWidth,
			"imageIsBg"     : $imageIsBg,
			"isRotation" 		: $isRotation,
			"nCount"				: $nCount,
			"opacity"				: $opacity,
			"size" 					: $size,
			"scale"         : $scale,
			"width"					: $width,
			"otherColor" 		: $otherColor
		});
	}
	
	
	/**
	 * Return Trả lại giá trị đúng nếu là mắt của QR code
	 * @param $typeTable
	 * @param $x
	 * @param $y
	 */
	isEyes($typeTable: any[], $x: number, $y: number){
		
		return !((
				!($typeTable[$x][$y] === QRPointType.$POS_CENTER
					|| $typeTable[$x][$y] === QRPointType.$POS_OTHER)
				&& (
					$typeTable[$x][$y] === QRPointType.$ALIGN_CENTER ||
					$typeTable[$x][$y] === QRPointType.$ALIGN_OTHER ||
					$typeTable[$x][$y] === QRPointType.$TIMING ||
					$typeTable[$x][$y] !== QRPointType.$POS_OTHER
				))
		);
	}
	
	
	/**
	 *
	 * @param $options
	 */
	abstract listPoints($options: any): any[];

	private elements = (items: any[]) =>{
		try {
			return items.map((e, key)=> <Fragment key={key}>{e}</Fragment>)
		}catch (e) {
			return <Fragment>{items}</Fragment>
		}
	}

	private map($datakeys: any[] = [], $data: any[] = []){
		let { "top_left" : $top_left , "top_right" : $top_right, "bottom_left" : $bottom_left }: any = $datakeys || {};
		try{
			let { type : $top_left_type  } = $top_left || {};
			let	{ type : $top_right_type } = $top_right || {};
			let { type : $bottom_left_type }  = $bottom_left || {};
			if($top_left_type >=  ($data || []).length){
				$top_left_type = 0;
			}
			if($top_right_type >=  ($data || []).length){
				$top_right_type = 0;
			}
			if($bottom_left_type >=  ($data || []).length){
				$bottom_left_type = 0;
			}

			let $maptl = $data[$top_left_type] || {};
			let $maptr = $data[$top_right_type] || {};
			let $mapbl = $data[$bottom_left_type] || {};

			return {
				top_left:     { ...$top_left,     ...$maptl},
				top_right:    { ...$top_right,    ...$maptr},
				bottom_left:  { ...$bottom_left,  ...$mapbl},
				bottom_right: { ...$bottom_left,  ...$mapbl}
			}
		}catch (e){}
	}

	protected eyes($options: any = {}): any {
		let { "isRotation": $isRotation, "eyes" : $eyes, "params" : $params } = $options || {};
		let { "eyeShape" : $eyeShape, "eyeInner" : $eyeInner } = $params || {};
		$eyeInner = this.map($eyeInner, dataEyes);
		$eyeShape = this.map($eyeShape, dataShape);
		if (!this.$qrcode) return [];
		let $nCount = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);
		let $pointList = [];

		for (let $x = 0; $x < $nCount; $x++) {
			for (let $y = 0; $y < $nCount; $y++) {
				if (this.$qrcode.isDark($x, $y) === false) continue;
				if ($typeTable[$x][$y] === QRPointType.$POS_CENTER) {
					try{
						$pointList.push( $eyes({
							"eyeInner" : $eyeInner,
							"eyeShape" : $eyeShape,
							"x": $isRotation ? ($nCount - 1 - $x):$x,
							"y": $y
						}));
					}catch ($e){
					}
				}
			}
		}

		return (<Fragment>{this.elements($pointList)}</Fragment>);
	}

	protected gradient($options: any){

		let {
			"viewKey" : $viewKey, "enabled" : $hasGradient, "type": $typeGradient, "degreeStr": $deg, "children" : $children,
			"eyeCustom" : $eyeCustom, "viewBox": $viewBox, "values": $values
		}: any = $options || {};

		let { 0: $points, 1: $eyes } = $children || [];


		if(!$hasGradient){
			return (<Fragment>{this.elements($children)}</Fragment>);
		}

		let $content = ($values || []).map(({ left, value }, key: number)=>{
			return <stop key={key} offset = {`${left}%`} stopColor = {value} />
		});

		if(!!$typeGradient){
			$content = <>
				<radialGradient id={`grad${$viewKey}`}>
					{$content}
				</radialGradient>
			</>
		}else{

			$deg = $deg || 90;

			let linearProps = {}
			try{
				let [x1,y1, x2, y2] = this.$coordinates[$deg];
				Object.assign(linearProps, {
					x1: `${x1}%`, x2: `${x2}%`, y1: `${y1}%`, y2: `${y2}%`
				})
			}catch (e) {}

			$content = <>
				<linearGradient {...linearProps} id={`grad${$viewKey}`}>
					{$content}
				</linearGradient>
			</>
		}

		if(!$eyeCustom){
			$points = (<Fragment>
				<g>{this.elements($points)}</g>
				<g>{this.elements($eyes)}</g>
			</Fragment>);
		}

		$content = <>
			<defs>{$content}<mask id={`gmask${$viewKey}`}>{this.elements($points)}</mask></defs>
		</>
		let $x = 0, $y = 0, $size = 1000;
		try{
			let [x, y, size]: any = `${$viewBox}`.split(" ");
			$x= x || 0;
			$y= y || 0;
			$size = size || 1000;
		}catch (e) {}

		let size: any = parseFloat(`${$size}`);

		$content = <>{$content}
			<rect x={$x} y={$y} width={size} height={size} fill={`url(#grad${$viewKey})`} mask={`url(#gmask${$viewKey})`} />
		</>

		if(!!$eyeCustom){
			$content = <>{$content}{$eyes}</>
		}

		return $content;
	}
	
	/**
	 *
	 * @param imageIsBg
	 * @param icon
	 * @param xZoom
	 * @private
	 */
	private drawIcon({ imageIsBg, icon, params:{ width: xZoom }= {} }: { imageIsBg: boolean, icon: any, params: any }): any {
		if (!this.$qrcode) return;
		const nCount = this.$qrcode.getModuleCount();
		const pointList = [];

		// draw icon
		if (icon) {
			const iconEnabled = getExactValue(icon.enabled, 0);
			let { src, imgWidth, imgHeight, ratio, viewbox, isSvg } = icon;

			try {
				if(isSvg && Window){
					src = atob(src);
				}
			}catch (e) {}
			if(isSvg) {
				try {
					let reg = new RegExp(/fill\=\"url\(\#(.*)\)/g);
					let myArray: any = reg.exec(src);
					if(myArray[1]){
						let id = myArray[1];
						let reg2 = new RegExp(id,'g');
						let random = Math.floor(Math.random() * 1000) + 1;
						src = src.replace(reg2, id + random);
					}
				}catch (e) {}
			}

			if (icon && iconEnabled) {
				const randomIdDefs = getIdNum();
				const randomIdClips = getIdNum();
				let vbs = [];
				try{
					vbs = viewbox.split(' ');
				}catch (e) {}

				let [,,vbw, vbh] = vbs;
				
				let options = { ratio, imgWidth, imgHeight }
				let DefsData: any;

				let stroke = 10;
				let style = 0;
				let baseRx = 0;
				
				if(ratio >= 30 && ratio < 80 ){
					style = 1
				}else if(ratio >= 80){
					style = 2
				}else{
					stroke = 50;
					baseRx = 10;
				}
				
				if(imageIsBg){
					style = 3
				}
				
				let autoProps = Object.assign({
					style, windowWidth: 100, width: imgWidth, height: imgHeight
				})
				
				if(icon?.zoom > 100 && imageIsBg){
					Object.assign(autoProps, {
						custom: `${icon?.zoom}:${icon?.zoom}`
					})
				}
				
				try{
					let [width, height ] = this.$autoSize(autoProps).split(':')
					Object.assign(options, { rx: baseRx, width, height });
				}catch (e) {}
				
				const zoom = ({ rx, xZoom, ratio, width, height, imgWidth, imgHeight}: any) => {
					let iconX: number, iconY: number, rectWidth: number, rectHeight: number;
					let size = nCount * xZoom;

					let ratW = width/imgWidth;
					let ratH = height/imgHeight;
					if(ratio){
						if( ratW < ratH){
							rectWidth = width;
							rectHeight = ratW * imgHeight;
						}else{
							rectWidth = ratH * imgWidth;
							rectHeight = height;
						}
					}else{
						rectWidth = ratH * imgWidth;
						rectHeight = ratW * imgHeight;
					}

					rectWidth = (rectWidth * size)/100;
					rectWidth = parseInt(`${rectWidth}`);
					rectHeight = (rectHeight * size)/100;
					rectHeight = parseInt(`${rectHeight}`);

					iconX = ((size - rectWidth) / 2);
					iconY = ((size - rectHeight) / 2);

					return { iconX, iconY, rectWidth, rectHeight, rx }
				}

				// xZoom = xZoom * 0.8;
				let { iconX, iconY, rectWidth, rectHeight, rx } = zoom({...options, xZoom});

				DefsData = (props: any) => <><rect {...props} fill="#FFF" width={rectWidth} height={rectHeight} transform={`translate(${iconX},${iconY})`} /></>

				pointList.push(<DefsData rx={rx} stroke="#FFF" strokeWidth={stroke} />);
				pointList.push(
					<>
						<defs><DefsData id={`defs-path${randomIdDefs}`} /></defs>
						<clipPath id={`clip-path${randomIdClips}`}><use href={`#defs-path${randomIdDefs}`}  overflow="visible"/></clipPath>
						{(!isSvg) && <g clipPath={`url(#clip-path${randomIdClips})`}>
							<image href={src} width={rectWidth} height={rectHeight} x={iconX} y={iconY}/>
						</g>}
						{(!!isSvg) && <g transform={`translate(${iconX},${iconY}) scale(${rectWidth/vbw},${rectHeight/vbh})`}>
							<svg viewBox={`${viewbox}`} width={vbw} height={vbh} dangerouslySetInnerHTML={{ __html: src }} />
						</g>}
					</>
				);
			}
		}
		return (<Fragment>{this.elements(pointList)}</Fragment>);
	}

	getTransform($viewBox: string, $max: number = 1000){
		let $pos = $viewBox.split(" ");
		let $x = Math.abs(parseFloat($pos[0]));
		let $a = Math.abs(parseFloat($pos[3]));

		let $scale = parseFloat(parseFloat(`${$max / $a}`).toFixed(4));
		let $position = parseFloat(`${$scale * $x}`).toFixed(4);
		return { transform: `translate(${$position},${$position}) scale(${$scale})` }
	}
	
	
	/**
	 * imageToQrcode
	 * @param $rest
	 * @param $content
	 * @param $imageIsBg
	 */
	imageToQrcode($rest: any[], $content: any, $imageIsBg: boolean = false){
		let __REST = [];
		
		if($imageIsBg){
			__REST.push($content);
			$rest.map((e: any)=>__REST.push(e));
		}else{
			$rest.push($content);
			__REST = $rest;
		}
		
		return __REST;
	}
	/**
	 * build data to svg
	 * @return string
	 */
	toSvg() {
		let $options = this.$options || {}
		
		let { "hasGradient": $hasGradient, "gradient": $gradient, "frameProps" : $frameProps 	        } = $options;
		let {	"viewKey" : $viewKey, "outside" : $outside, "params" : $params, "imageIsBg": $imageIsBg } = $options;
		let { "align": $align, "pos" : $pos, "icon" : $icon, "isCircle": $isCircle                    } = $options;
		let { "isRotation" : $isRotation, "eyeCustom" : $eyeCustom, "frameProps": $frame			        } = $options;
		let { "frameBody": $frameColor, "circleColor": $circleColor                                   } = $frame || {}
		let { "width" : $width, "otherColor": $color                                                  } = $params || {};
		
		const random = Math.floor(Math.random() * 1000) + 1;

		$viewKey = $viewKey || `${random}`.replace(".", "-");
		if (!!$hasGradient) {
			$params = {...$params, otherColor: '#fff'};
		}

		const $nCount = this.$qrcode.getModuleCount();
		let $out = 0;
		let $distance = $out + 1.5;

		//Thiết lập đường tròn
		if ($isCircle) {
			let $space = $nCount/15;
			if($space < 1) $space = 1.5;
			else if($space > 15 ) $space = 15;
			$space -= 2;

			const $r = Math.ceil($nCount * Math.sqrt(2));

			$out = Math.round(($r - $nCount) / 2 + $space);
			$distance = $out + $space;

			// debug({ $space, $distance, $out, $nCount})
		}


		const $viewBox = this.getViewBox($width, $distance);

		const $values: any[] = [];
		
		let listProps: any = Object.assign($options, {
			'out': $out, 'isRotation': $isRotation, 'params': $params,
			'align': $align, 'pos': $pos, 'icon': $icon, "imageIsBg": $imageIsBg
		});
		
		$values.push(this.listPoints(listProps));
		$values.push(this.eyes({ "isRotation": $isRotation, "params": $params,
			"eyes": eyeRenders($eyeCustom, $hasGradient ? '#ffffff': $color, $width)
		}));

		let $content: any = [];
		let $rest: any = [];
		
		$rest.push(this.gradient({
			...$gradient,
			...{
				"isCircle": $isCircle,
				"children": $values,
				"eyeCustom": $eyeCustom,
				"viewBox": $viewBox,
				"viewKey": $viewKey
			}
		}));

		let $r = 480;
		let circleOptions = {
			"r" 					: $r,
			"cx" 					: 500,
			"cy" 					: 500,
			"fill"				: $circleColor,
			"stroke"			: $frameColor || $color || 'black',
			"strokeWidth"	: 30
		}

		// @ts-ignore
		if($isCircle){
			$outside = 25;

			let $circle = Circle({ ...circleOptions });
			$content.push(<g>{$circle}</g>);
		}
		
		let $drawIcon = this.drawIcon({ imageIsBg: $imageIsBg, params: $params, icon: $icon });
		$rest = this.imageToQrcode($rest, $drawIcon, $imageIsBg);
		$content.push(<g {...this.getTransform($viewBox)}>{this.elements($rest)}</g>);
		$content = this.elements($content);


		if($outside){
			let $len = 1000;
			let $res = [];
			$res.push(-$outside);
			$res.push(-$outside);
			$res.push(($len + $outside * 2));
			$res.push(($len + $outside * 2));
			$content = (<g {...this.getTransform($res.join(" "))}>{$content}</g>);
		}
		else{
			$content = (<Fragment>{$content}</Fragment>);
		}

		// @ts-ignore
		return (<Frame {...$frameProps}>{$content}</Frame>);
	}

}

export default Base;
