import { graphql } from 'react-apollo';
// @ts-ignore
import map from '@gqlapp/base/utils/map';
import QUERY from '../graphql/getQrcodeIcon.graphql';

export default graphql(QUERY, {
	props: ({ mutate }: any) => ({
		getQrcodeIcon: async (variables: any) =>{
			return map(
				map(await mutate({ variables }), ({ data }: any) => data),
				({ res }: any) => res
			)
		}
	})
});
