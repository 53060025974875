import React, { Fragment } from "react";
import Base from './Base';
import {QRCode, QRPointType} from '../qrcode';
import { getTypeTable, pointCallback } from "./ultil";

export class LineBase extends Base {

	/**
	 * @var array
	 */

	constructor($options: any = {}, $qrcode: QRCode) {
		$options = Object.assign({}, {
			"size" : 50,
			"posType" : 3,
			"otherColor" : '#000000',
			"posColor" : '#000000',
		}, $options)
		super($options, $qrcode);
	}


	/**
	 *
	 * @param $options
	 */
	listPoints($options: any): any[] {
		let { "params" : $params, 'isRotation' : $isRotation, "out" : $out }: any = $options || {};
		let { "size" : $size, "opacity" : $opacity, "otherColor" : $otherColor, "width" : $width }: any = $params || {};

		if (!this.$qrcode) return [];

		let $type = this.$type;
		let $nCount = this.$qrcode.getModuleCount();
		let $typeTable = getTypeTable(this.$qrcode);
		let $pointList: any[] = [];

		$size = ($size > 80 && $size < 120 ? $size :  80) / 100;
		$otherColor = $otherColor || 'black';

		let $available: any[] = [];
		let $ava2: any[] = [];

		let $max = $nCount + $out;
		let $min = -$out;

		for (let $i = $min; $i < $max; $i++) {
			$available[$i] = [];
			$ava2[$i] = [];
			for (let $j = $min; $j < $max; $j++) {
				$available[$i][$j] = false;
				$ava2[$i][$j] = false;
			}
		}


		pointCallback(this.$qrcode, $nCount, $out, ($i: number, $j: number, $x: number, $y: number) => {
			if (this.isEyes($typeTable, $x, $y)) return;
			if (!this.$qrcode.isDark($x, $y)) return;
			
			if(!($typeTable[$x][$y] == QRPointType.$POS_CENTER || $typeTable[$x][$y] == QRPointType.$POS_OTHER))
				$available[$i][$j] = true;
		});

		if($isRotation){
			for (let $i = $min; $i < $max; $i++) {
				for (let $j = $min; $j < $max; $j++) {
					if($available[$i][$j]){
						let $x = $max - ($i - $min + 1);
						$ava2[$x][$j] = true;
					}
				}
			}
			$available = $ava2;
		}

		const Line = ($options = {}) =>{
			let { "x1" : $x1, "x2" : $x2, "y1" : $y1, "y2" : $y2 }: any = $options || {};
			let $attribute = {
				x1: $width * $x1,
				x2: $width * $x2,
				y1: $width * $y1,
				y2: $width * $y2,
				opacity: $opacity,
				strokeWidth 	: $size * $width,
				stroke				: $otherColor,
				strokeLinecap : "round"
			}
			// @ts-ignore
			return <line {...$attribute} />;
		}

		const Circle = ($x: number, $y: number) => {
			let $cx = $x + 0.5
			let $cy = $y + 0.5

			let $attribute = {
				fill: $otherColor,
				r: $width * ($size / 2),
				cx: $width * $cx,
				cy: $width * $cy
			}

			return <circle {...$attribute} />;
		}

		for (let x = -$out ; x < $nCount + $out; x++) {
			for (let y = -$out; y < $nCount + $out; y++) {
				let $x1 = x + 0.5;
				let $x2 = x + 0.5;
				let $y1 = y + 0.5;
				let $y2 = y + 0.5;

				if ($available[x][y]){
					let start = 0;
					let end = 0;
					let ctn = true;
					while (ctn && y + end < $nCount + $out) {
						try {
							if ($available[x][y + end] && $ava2[x][y + end] && end - start <= 3) {
								end++;
							} else {
								ctn = false;
							}
						}catch (e) {
							ctn = false;
						}
					}
					if (end - start > 1) {
						for (let i = start; i < end; i++) {
							try {
								$available[x][y + i] = false;
							}catch (e) {}
						}
						let $len = end - start;

						$pointList.push(Line({
							x1: $x1,
							x2: $x2,
							y1: $y1,
							y2: y + $len - 0.5
						}))
					}
				}

				if($type == 2 && $available[x][y]){
					let start = 0;
					let end = 0;
					let ctn = true;
					while (ctn && y + end < $nCount + $out) {
						try{
							if ($available[x + end][y] && end - start <=3) {
								end++;
							} else {
								ctn = false;
							}
						}catch (e) {
							ctn = false;
						}
					}
					if (end - start > 1) {
						for (let i = start; i < end; i++) {
							$available[x + i ][y] = false;
						}
						let $len = end - start;

						$pointList.push(Line({
							x1: $x1,
							x2: x + $len - 0.5,
							y1: $y1,
							y2: $y2
						}))
					}
				}

				if($available[x][y] ) $pointList.push(Circle(x, y));
			}
		}

		return $pointList.map((e, key)=> <Fragment key={key}>{e}</Fragment>);
	}
}

export default LineBase;
