import React from 'react';
import clsx from 'clsx';
import Button from 'reactstrap/lib/Button';
import Close from "@xem/icon/qrcode/Close.svg";
import Download from "@xem/icon/qrcode/Download.svg";
import Share from "@xem/icon/qrcode/Share.svg";

import qrtag from '@xem/qr-common/utils/qrtag';
import Base from "../../../../components/QrView/QrView";
import Col from "@gqlapp/base/Col";

import Row from "@gqlapp/base/Row";
import FormBase from 'reactstrap/lib/Form';

function Header(props){
	let { values, data_text, isQrTag, toDefault, shareFile } = props;
	const { apply, btnSetDefault, append, onClose, handleSubmit, setDefault } = props;
	const [state, setState] = React.useState(false);
	
	let buttons = props?.buttons || [];
	if(isQrTag){
		data_text = qrtag(data_text);
	}

	const data = { url: "https://example.do" }

	// debug({ data, data_text, isQrTag });
	
	React.useEffect(function (){
		const allSupported = Object.entries(data).every(([key, value]) => {
			return navigator && navigator.canShare && navigator.canShare({ [key]: value });
		});
		
		if(allSupported){
			setState(true);
		}

		// if(!!window){
		// 	debug('window')
		// }
	}, [data])
	
	function onDownload(){
		let items = buttons.map(({ onClick, label, ...value })=>({
				...value,
				title: ()=> label,
				handleClick: onClick,
				wrapper: (props)=><Col {...props} xs={6} />,
				btnProps: { style: { lineHeight: '38px', borderRadius: '5px', textAlign: 'center' } }
			}) );

		let menus = [];
		menus.push({ type: 'group', items, wrapper: (props)=><Row {...props} medium /> })

		try{
			props.onActions['clicks']({
				enableClose: true,
				className: 'theme-light',
				append: append(),
				title: 'qr:label.popup.download'.t(),
				items: menus,
				sx:{
					'& div[data-modal-header], & div[data-modal-scroll], & div[data-modal-scroll] > div[data-modal-body]':{
						margin: 0,
						backgroundColor: '#f2f2f2'
					},
					'& div[data-modal-scroll] > div[data-modal-body]':{
						padding: '0 15px'
					}
				}
			});
		}catch (e) {
			debug(e)
		}
	}

	return <>
		<Button onClick={onClose} color={'default'} className={'p-0  modal-close'} style={{ position: 'absolute', right: '10px', top: '8px'}}><Close width={24} height={24}/></Button>
		<>
			<Base
				{...{ values: {...values, data_text}}}
				{...{ styleWrap: ({renderer}) => renderer }}
				{...{ viewStyle: { padding: '15px', height: '230px' }}}
			/>
		</>
		<div className={'d-flex justify-content-center'}>
			<Row className={'d-flex'} medium>
				{(!!toDefault) && <Col xs={'auto'} sx={{'& .btn': { height: '36px', '--btn-light': '#F2F2F2', 'color': '#000000'}}}>
					<Button color={'light'} onClick={setDefault}>{btnSetDefault}</Button></Col>}
				{(!!apply) && <Col xs={'auto'}>
					<FormBase name="create" onSubmit={handleSubmit}>
						<Button
							type={'submit'} className={'border-0'} color={'info'}
							style={{ height: '36px', '--btn-info': '#E7F3FF', 'color': '#0066CC' }}
						>{apply}</Button>
					</FormBase>
				</Col>}
				<Col xs={'auto'}>
					<Button onClick={onDownload} className={'border-0 p-0'} color={'secondary'} style={{'--btn-secondary': '#E7F3FF'}}>
						<Download/>
					</Button>
				</Col>
				{(!!state)&& <Col xs={'auto'} suppressHydrationWarning>
					<Button
						onClick={shareFile('png')}
						className={'border-0 p-0'} color={'secondary'}
						style={{'--btn-secondary': '#E7F3FF'}}>
						<Share/>
					</Button>
				</Col>}
			</Row>
		</div>
	</>
}

export default Header;