import React, { Fragment } from 'react';
import { QRCode, QRPointType, ErrorCorrectLevel } from '../qrcode';
import filterObject from "@gqlapp/base/utils/filterObject";
import {callback} from "@xem/entity/classes/renderCallback";

let seed = 0;

let idNum = 0;

export function rand(min: number, max: number) {
	seed = (seed * 9301 + 49297) % 233280;
	return min + (seed / 233280.0) * (max - min);
}


export function getIdNum() {
	idNum += 1
	return idNum.toString()
}


export function getExactValue(value: any, defaultValue: any) {
	if (typeof value != "string") return value;
	if (value.length <= 0) value = defaultValue;
	if (!isNaN(value)) value = parseInt(value);
	return value;
}

export function getRound($i: number, $j: number, $c: number, $w: number){
	let $r = ($c + $w * 2)/2;
	$i = $i + $w;
	$j = $j + $w;
	if($w > 0 && !(Math.sqrt(Math.pow($r - $j, 2) + Math.pow($r - $i, 2)) < $r - 2)){
		return false;
	}
	return true;
}

export function getSquare($i: number, $j: number, $count: number, $out: number){
	if($i + 1 == 0 || $j + 1 == 0 ){
		return false;
	}
	return true;
}

export function pointCallback($qrcode: QRCode, $nCount: any, $out: number, $callback: any){

	//ĐỘ phủ của mã QR ngoài vùng vuông
	let $tab = 8;
	//Khoảng trắng giữa mã QR code
	let $space = 0;
	//Độ lệch giữa bán kính hình tròn và QR
	let $deviation = 0.5;

	for (let $i = 0 - $out; $i < ($nCount + $out); $i++) {
		for (let $j = 0 - $out; $j < ($nCount + $out); $j++) {
			let $x = $i;
			let $y = $j;

			// Phần out bên trái
			if($i > $nCount){
				$x = ($i - $nCount) + $tab;
			}

			// Phần out bên phải
			else if($i < 0){
				$x = $i + $out + $tab + $space;
			}

			// Phần out bên dưới
			if($j > $nCount){
				$y = ($j - $nCount) + $tab;
			}
			// Phần out bên trên
			else if($j < 0){
				$y = $out + $j + $tab + $space;
			}

			try{
				if(!getRound($i + $deviation, $j + $deviation, $nCount, $out)) continue;
				if(!getSquare($i, $j, $nCount, $out)) continue;
				$callback($i, $j, $x, $y);
			}catch (e) {}
		}
	}
}

/**
 * rotate
 * @param $data
 * @param $trans
 * @param $transform
 * @param $attr
 */
const rotate = ($data: any, $trans: any, $transform: any, $attr: any) => {
	$trans = $trans || {};
	$trans = $transform($trans);
	if ($trans) {
		$attr = { ...$attr, transform: $trans };
	}
	return <g {...$attr}>{$data}</g>;
};

/**
 * getTypeTable
 * @param $qrcode
 */
export function getTypeTable($qrcode: QRCode) {
	const $nCount = $qrcode.getModuleCount();
	const position = $qrcode.getPositionTable();
	const PD = [[3, 3], [3, $nCount - 4], [$nCount - 4, 3]];

	let typeTable = new Array($nCount);
	for (let i = 0; i < $nCount; i++) typeTable[i] = new Array($nCount);

	for (let i = 8; i < $nCount - 7; i++) {
		typeTable[i][6] = typeTable[6][i] = QRPointType.$TIMING;
	}

	for (let i = 0; i < position.length; i++) {
		typeTable[position[i][0]][position[i][1]] = QRPointType.$ALIGN_CENTER;
		for (let r = -2; r <= 2; r++) {
			for (let c = -2; c <= 2; c++) {
				if (!(r === 0 && c === 0))
					typeTable[position[i][0] + r][position[i][1] + c] = QRPointType.$ALIGN_OTHER;
			}
		}
	}

	for (let i = 0; i < PD.length; i++) {
		typeTable[PD[i][0]][PD[i][1]] = QRPointType.$POS_CENTER
		for (let r = -4; r <= 4; r++) {
			for (let c = -4; c <= 4; c++) {
				if (PD[i][0] + r >= 0 && PD[i][0] + r < $nCount && PD[i][1] + c >=0 && PD[i][1] + c < $nCount)
					if (!(r === 0 && c === 0))
						typeTable[PD[i][0] + r][PD[i][1] + c] = QRPointType.$POS_OTHER;
			}
		}
	}

	for (let i = 0; i <= 8; i++) {
		if (i !== 6) typeTable[i][8] = typeTable[8][i] = QRPointType.$FORMAT;
		if (i < 7) typeTable[$nCount - i - 1][8] = QRPointType.$FORMAT;
		if (i < 8) typeTable[8][$nCount - i - 1] = QRPointType.$FORMAT;
	}

	for (let i = $nCount - 11; i <= $nCount - 9; i++) {
		for (let j = 0; j <= 5; j++) {
			typeTable[i][j] = typeTable[j][i] = QRPointType.$VERSION;
		}
	}

	for (let i = 0; i < $nCount; i++) {
		for (let j = 0; j < $nCount; j++) {
			if (!typeTable[i][j]) typeTable[i][j] = QRPointType.$DATA;
		}
	}

	return typeTable;
}

/**
 *
 * @param $custom
 * @param $gb
 * @param $color
 */
const eyeColor = ({ "custom" : $custom, "gb" : $gb , "color" : $color }: any) =>
	({ fill: $custom ? $color : $gb })

/**
 * funcInnerEye
 * @param $hasGradient
 * @param $source
 * @param $transform
 */
const funcInnerEye = ({ "source" : $source, "transform" : $transform, ...$options}: any) => {
	let { "data" : $data, "color" : $color, "transform" : $trans, "size" : $size }: any = $source || {};

	$size = $size || 40;

	const $pos  = (100 - $size) / 2;
	const $scale = ($size / 100);

	const $attr = eyeColor({...$options, "color": $color });
	const $g_attr = { transform : ($pos || $scale) ? `translate(${$pos}, ${$pos}) scale(${$scale})` : "", };
	return rotate(<g {...$g_attr}>{$data}</g>, $trans, $transform, $attr)
};

/**
 * funcShapeEye
 * @param $hasGradient
 * @param $source
 * @param $transform
 */
const funcShapeEye = ({ "source" : $source, "transform" : $transform, ...$options}: any) => {
	const { "data" : $data, "color" : $color, "transform" : $trans }: any = $source || {};
	const $attr = eyeColor({...$options, "color": $color });
	return rotate(<Fragment>{$data}</Fragment>, $trans, $transform, $attr)
};

/**
 * transform
 * @param type
 */
const transform = (type: string) => ({ tl, tr, bl, br }: any) => {
	switch (type) {
		case 'top_left':
			if (tl) {
				return `rotate(${tl} 50 50)`;
			}
			break;
		case 'top_right':
			if (tr) {
				return `rotate(${tr} 50 50)`;
			}
			break;
		case 'bottom_right':
			if (br) {
				return `rotate(${br} 50 50)`;
			}
			break;
		default:
			if (bl) {
				return `rotate(${bl} 50 50)`;
			}
			break;
	}
};

/**
 * eyeRenders
 * @param $custom
 * @param $gb
 * @param $width
 */
export const eyeRenders = ($custom: boolean, $gb: string, $width: number) => ($options: any = {}) => {
	let { "eyeInner" : $eyeInner, "eyeShape" : $eyeShape, "x" : $x, "y" : $y } = $options || {};
	$width = $width ? $width : 1;
	$x = $x - 3;
	$y = $y - 3;

	let $dataType;

	if ($x === $y &&
		$x === 0) 						{ $dataType = 'top_left'; }
	else if ($x > $y) 			{ $dataType = 'top_right'; }
	else if ($x < $y) 			{ $dataType = 'bottom_left'; } else 									{ $dataType = 'bottom_right'; }

	const $ei = funcInnerEye({
		gb 					: $gb,
		custom			: $custom,
		source 			: $eyeInner[$dataType],
		transform 	: transform($dataType)
	});

	const $ef = funcShapeEye({
		gb 					: $gb,
		custom			: $custom,
		source 			: $eyeShape[$dataType],
		transform 	: transform($dataType)
	});

	const svg = <svg {...{ viewBox: '0 0 100 100', x: "0", y: "0", width: "100", height: "100"}}>
		<Fragment key={1}>{$ei}</Fragment>
		<Fragment key={2}>{$ef}</Fragment>
	</svg>;

	return <g {...{transform: `translate(${$x * $width},${ $y * $width}) scale(${ 0.07 * $width})` }}>{svg}</g>
};

/**
 * hexToRgb
 * @param hex
 * @param opacity
 */
export function hexToRgb(hex: string, opacity: number = 1) {
	try {
		//@ts-ignore
		return (hex = `${hex}`.replace('#', ''))
			.match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
			.map((l) => parseInt(hex.length % 2 ? l + l : l, 16));
	} catch (e) {
		return [0, 0, 0];
	}
}

/**
 * hexToRgbTo
 * @param hex
 * @param opacity
 */
export function hexToRgbTo(hex: string, opacity: number = 1) {
	const [r, g, b ] = hexToRgb(hex, opacity);
	return 0.299 * r + 0.587 * g + 0.114 * b;
}

/**
 * brighterToRgb
 * @param hex
 * @param bright
 * @param opacity
 */
export function brighterToRgb(hex: string, bright: number = 1.5, opacity: number = 1) {
	let [r, g, b ] = hexToRgb(hex, opacity);
	r = Math.min(255, r * bright);
	g = Math.min(255, g * bright);
	b = Math.min(255, b * bright);
	return `rgb(${r},${g},${b}, ${opacity})`;
}


/**
 *
 * @param $options
 */
export function mapValues($options = {}) {
	const { "eyeShape" : $eyeShape, "eyeInner" : $eyeInner, "color" : $color, 'size': $size, "zoom": $zoom }: any					            = $options || {};
	const { "backgroundColor" : $backgroundColor, "dataStroke" : $dataStroke, "image" : $imgID, "paramValue" : $paramValue }: any   = $options || {};
	const { "imageIsBg": $imageIsBg, "stroke": $stroke, "strokeWidth": $strokeWidth }: any              = $options || {};
	const { "align" : $align, "pos" : $pos, "data_text" : $data_text }: any                             = $options || {};
	
	let { "selectedIndex" : $selectedIndex, "eyeCustom" : $eyeCustom, "icon" : $icon, "gradient" : $gradient }: any = $options || {};
	let { "outside" : $outside, "typeNumber" : $typeNumber, "correctLevel" : $correctLevel 	}: any = $options || {};
	let { "isCircle" : $isCircle, "isRotation" : $isRotation 																}: any = $options || {};
	let { "frame" : $frame, "frameClassName" : $className																		}: any = $options || {};
	let { "textColor" : $textColor, "style": $kind, "color" : $frameColor, "text" : $text 	}: any = $frame || {};
	
	let { svg, imgWidth, imgHeight }      = $icon || {};
	let { width, height, viewbox, isSvg } = svg || {};
	imgWidth = width || imgWidth || 300;
	imgHeight = height || imgHeight || 300;
	const ratio = ((imgWidth - imgHeight) / imgWidth) * 100;
	$icon = { ...$icon, ratio, viewbox, isSvg, imgWidth, imgHeight, isRotation: ratio >= 5 }

	$frame = $frame || {};

	$typeNumber = $typeNumber >= 0 && $typeNumber <= 40 ? $typeNumber : -1;
	$frameColor = $frameColor || '#000';

	const $rgb = hexToRgbTo($frameColor);

	$textColor = $textColor || $rgb < 180 ? '#ffffff' : '#000000';

	if(typeof $isRotation == 'undefined'){
		$isRotation =  true;
	}

	let $eyeDefault = {
		top_left: { type: 1, color: '#000000' },
		bottom_left: { type: 1, color: '#000000' },
		top_right: { type: 1, color: '#000000' }
	}


  const map = (v: any) =>{
    let values = v || {}
	  const keys = ['font', 'style', 'icon', 'type', 'enabled']
    Object.keys(values).map(key=> {
      if(keys.includes(key)){
        try{
					values[key] = parseInt(values[key]);
				}catch (e) {
        	console.error(key, values )
        }
      }
    });
	  return values;
  }

  $frame = map($frame);
  $gradient = map($gradient);
	
	
	return Object.assign(
		{ stroke: $stroke, strokeWidth: $strokeWidth, size: $size, dataStroke : $dataStroke },
		{ zoom: $zoom, isRotation: $isRotation, typeNumber: $typeNumber, data_text: $data_text },
		{
			image: $imgID || 0,
			outside: $outside || 0,
			isCircle: !!$isCircle,
			eyeCustom: !!$eyeCustom,
			correctLevel: $correctLevel || ErrorCorrectLevel.H,
			paramValue: $paramValue || {},
			selectedIndex: $selectedIndex || 0,
			align: $align, pos: $pos, imageIsBg: $imageIsBg || false,
			hasGradient: $gradient && $gradient.enabled ? 1 : 0
		},
		{ icon				: {
			scale: 33, bg: 0, size: 0, ...$icon,
			enabled: $icon && $icon.src ? 1 : 0,
			src: $icon && $icon.src ? $icon.src : "",
		}},
		{ gradient: { type : 0, enabled : 0, start : '#000000', stop : '#4a90e2', ...$gradient }},
		{ params: {
			otherColor: $color, width: 30,
			eyeShape: { ...$eyeDefault, ...$eyeShape },
			eyeInner: { ...$eyeDefault, ...$eyeInner }
		}},
		{
		frameProps: {
			...$frame,
			opacity 		: 1,
			text 			  : $text,
			textColor		: $textColor,
			kind			  : $kind || 'frame0',
			className		: $className || 'Qr-item-svg',
			frameBody		: $frameColor || '#A32222',
			circleColor :  $backgroundColor || 'none',
			backgroundColor : ($isCircle ? 'none' : $backgroundColor) || 'none'
		}
	});
}

/**
 *
 * @param $options
 * @constructor
 */
export function Line($options = {}){
	let $data: any = filterObject($options, function ($value: any, $key: string){
		return !!$value && ['x1', 'x2', 'y1', 'y2', 'stroke', 'strokeWidth', 'strokeLinecap'].includes($key);
	});

	return React.createElement('line', $data);
}

/**
 *
 * @param $options
 * @constructor
 */
export function Circle($options = {}){
	let $data: any = filterObject($options, function ($value: any, $key: string){
		return !!$value && ['r', 'cx', 'cy', 'stroke', 'strokeWidth', 'fill'].includes($key);
	});

	return React.createElement('circle', $data);
}


export function pointOptions($options: any, $keys: any[] = [], $defaultKeys: any[] = ['x', 'y', 'cx', 'cy'], $callback?: any){
	let { "x" : $x, "y": $y, "isRotation" : $isRotation, "nCount" : $nCount  }: any = $options || {}
	let { "width" : $width, "size" : $size, "extend" : $extend  }: any = $options || {}
	
	if($options?.imageIsBg){
		$keys = $keys.concat(['stroke', 'strokeWidth']);
	}else{
		$size = 1;
	}
	
	$width = $width || 1;
	$x= $isRotation ? ($nCount - 1 - $x): $x;
	$extend = $extend || 0;
	$callback = $callback || (($options: any)=>$options)
	
	let $_options: any = Object.assign({},
		$options,
		{
		"x" : (($x + $width * (1 - 1)/2) * $width) || 0,
		"y"	: (($y + $width * (1 - 1)/2) * $width) || 0,
		"width" :  $size * $width + $extend,
		"height" : $size * $width + $extend,
		"r-random" : 0.5 * (rand(33,100)/100) * $width,
		"scale-random" : rand(69,100)/100,
		"r" : ($size / 2) * $width || 0,
		"cx" : ($x + 0.5) * $width || 0,
		"cy" : ($y + 0.5) * $width || 0,
		"scale" : ($size/100) * $width
	});
	
	$_options = $callback($_options);
	
	if($keys.length){
		$_options = filterObject($_options, ($value: any, $key: any)=>{
			// @ts-ignore
			return !!$value && $keys.includes($key) || $defaultKeys.includes($key)
		})
	}
	
	return $_options;
}
