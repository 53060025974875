import clsx from 'clsx';
import React from "react";
import Group from "@gqlapp/base/utils/Group";
import Row from "@gqlapp/base/Row";
import Col from "@gqlapp/base/Col";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import RenderSlider from "@gqlapp/base/RenderSlider";
import Box from "@mui/material/Box";
import { ErrorCorrectLevel } from "@gqlapp/qrcode/qrcode";

const BaseForm =
((props) => {
	let {  values, setFieldValue } = props;
	let { settings, correctLevel } = values;

	
	const sx = {
		padding: '0 15px 15px', backgroundColor: '#ffffff',
		'& .MuiFormControl-root':{ marginTop: '8px', marginBottom: 0, minHeight: '54px' },
		'& >.row':{
			paddingTop: '15px',
			'& > .col':{ alignItems: 'center', alignContent: 'center' }
		}
	}
	
	
	let group = {
		...sx,
		paddingleft: '15px',
		paddingRight: '15px',
		paddingBottom: '0px',
		'& .MuiFormGroup-root':{ minHeight: '30px' },
	}
	
	const marks = [
		{ value: 0, label: '7%' },
		{ value: 1, label: '15%' },
		{ value: 2, label: '25%' },
		{ value: 3, label: '30%' }
	];
	
	
	function valueText(value) {
		return `${value}%`;
	}
	
	return (<>
		<Group sx={group} className={clsx({ label_on: settings?.on })}>
			
			<Row>
				<Col>{`page:block.correctLevel.title`.t()}</Col>
				<Col xs={'auto'} className={'d-flex align-items-center'} sx={{ width: '130px !important' }}>
					<Box {...{ sx:{ width: '100%' } }}>
						<Field {...{
							className: clsx('slider', 'crop-slider', 'd-flex align-items-center'),
							onBlur: () => {}, onFocus: () => {},
							component: RenderSlider,
							valueLabelFormat: valueText,
							onChange: setFieldValue, marks, step: null,
							name: 'correctLevel', value: correctLevel, min: 0, max: 3
						}} />
					</Box>
				</Col>
			</Row>
			{(!!values?.imageIsBg) && <Row>
				<Col>{`page:block.zoom.title`.t()}</Col>
				<Col xs={'auto'} className={'d-flex align-items-center'} sx={{width: '130px !important'}}>
					<Box {...{sx: {width: '100%'}}}>
						<Field {...{
							className: clsx('slider', 'crop-slider', 'd-flex align-items-center'),
							onBlur: () => {
							}, onFocus: () => {
							},
							component: RenderSlider,
							onChange: setFieldValue,
							// valueLabelFormat: valueText,
							name: 'icon.zoom', value: values?.icon?.zoom, min: 100, max: 200, step: 1
						}} />
					</Box>
				</Col>
			</Row>}
		</Group>
	</>)
})

export default BaseForm;