import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";
/**
 * @QrProvider(
 *   id = "round_in",
 *   title = @Translation("RoundIn"),
 *   type = 11
 * )
 */
export class RendererRoundIn extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererRoundIn', {$options})
		super({...$options, type: 11 }, $qrcode)
	}
}
