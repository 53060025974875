import React, { Fragment } from "react";
import clsx from 'clsx';

import Box from "@mui/material/Box";
import Top from "@xem/icon/base/Top";
import Button from "reactstrap/lib/Button";

import Col from "@gqlapp/base/Col";
import Row from "@gqlapp/base/Row";
import WidgetBox from "@gqlapp/base/View/WidgetBox";
import { FieldAdapter as Field } from "@gqlapp/forms-client-react";
import RenderCheckBox from "@gqlapp/base/RenderCheckBox";
import ColorField from "./ColorField";

const BaseForm = ((props) => {
	let { append, values, setFieldValue, onActions, setScrollTop, zoomin, data_text } = props;
	let { color, frame, backgroundColor, eyeCustom, eyeShape, eyeInner } = values;
	let widgetProps = { color: 'qrcode' }

	let obj = {
		options:{ webType: 'cpanel' },
		values: { ...values, data_text }, append,
		onActions, onChange: setFieldValue
	}

	return (<>
		<Box>
			<Button
				onClick={() => setScrollTop(true)}
				color={'default'} className={clsx('p-0', 'circle-up', {'in': zoomin})}
				style={{ position: 'fixed', bottom: '60px', right: '2px', zIndex: 10 }}>
				<Top/>
			</Button>
			<WidgetBox {...widgetProps}>
				<Row medium>
					<Col xs={'auto'}>
						<p style={{ marginBottom: '14px'}} className={'text-h4 font-weight-bold'}>{'Màu khung QR'.t()}</p>
						<ColorField
							{...{
								...obj,
								name: 'frame.color',
								value: frame.color
							}}
						/>
					</Col>
					
					<Col>
						&nbsp;
					</Col>
					<Col xs={'auto'}>
						<p style={{marginBottom: '14px'}} className={'text-h4 font-weight-bold'}>{'Màu nền'.t()}</p>
						<ColorField
							{...{
								...obj,
								name: 'backgroundColor',
								value: backgroundColor
							}}
						/>

					</Col>
					
				</Row>
				{(values?.imageIsBg) && <Row medium>
					<Col>
						<p style={{marginBottom: '14px'}} className={'text-h4 font-weight-bold'}>{'Màu nền'.t()}</p>
						<ColorField
							{...{
								...obj,
								name: 'dataStroke',
								value: values?.dataStroke
							}}
						/>
					</Col>
				</Row>}
			</WidgetBox>

			<WidgetBox title={'Màu dữ liệu'.t()} {...widgetProps}>
				<Row medium sx={{
					'& > [class*="col"], &> [class*="col-"]': {
						justifyContent: 'center',
						display: 'flex',
						'& >.MuiBox-root':{
							height: '40px'
						},
						'& .color':{
							padding: '8px 10px'
						},
						'& .MuiFormControlLabel-root': {
							margin: '0px'
						}
					}
				}}>
					<Col xs={'auto'}>
						<ColorField
							gradient
							{...{
								...obj,
								name: 'color',
								value: color
							}}
						/>
					</Col>
					<Col xs={'auto'}>
						<Field
							{...{
								component: RenderCheckBox,
								inline: true,
								label: <span className={'text-h4'}>{'Màu hoa văn'.t()}</span>,
								className: 'color-mobile-font',
								name: 'eyeCustom',
								value: eyeCustom,
								onChange: setFieldValue,
							}}
						/>
					</Col>
					<Col>&nbsp;</Col>
				</Row>
			</WidgetBox>
			<>
				{(!!eyeCustom) && <Box sx={{
					'& .MuiSelect-Color-Input':{
						minHeight: '36px'
					}
				}}>
					{['top_left', 'top_right', 'bottom_left'].map((val, idx) => <Fragment key={idx}>
						<WidgetBox
							title={`qr:eyes.${val}`.t()}
							{...widgetProps}
						>
							<Row medium>
								<Col xs={'auto'}>
									<ColorField
										{...{
											...obj,
											name: `eyeShape.${val}.color`,
											value: (eyeShape[val] || {}).color
										}}
									/>
								</Col>
								<Col xs={'auto'}>
									<ColorField
										{...{
											...obj,
											name: `eyeInner.${val}.color`,
											value: (eyeInner[val] || {}).color,
										}}
									/>
								</Col>
							</Row>
						</WidgetBox>
					</Fragment>)}
				</Box>}
			</>
		</Box>
	</>)
})


export default BaseForm;