// @ts-ignore
import {withFormik} from "formik";
import {withCrop} from "@xem/entity";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Medias from "@xem/media-common/classes/Medias";
import saveStatus from "@gqlapp/base/utils/saveStatus";
import { withQrOptions } from "@xem/qr-client-react/config";
import withMedias from "@xem/media-common/classes/withMedias";
import withQrUpload from "@xem/qr-common/classes/withQrUpload";
import QrValueMap from "@xem/qr-common/classes/QrValueMap";
import withMediaDeleting from "@xem/media-common/classes/withMediaDeleting";
import withStyle from '@xem/qr-client-react/helpers/popup/qrcode/render/Style';
import withFrameOptions from "../helpers/popup/qrcode/render/Form.options";
import getQrcodeData from '../helpers/getQrcodeData';
import withPreOptions from "./Vietbank.options";
import vietbankStyle from "./Vietbank.style";
/**
 * Upload file
 * @param Save
 */
const onUpload = (Save: any) => async (input: any) => {
	const values = await Save(input);
	return saveStatus({ values, errorMsg: 'qr:update.errorMsg'.t()});
};


const mapPropsToValues = function mapPropsToValues({ weight, data  }: any){
	let { content } = data || {};

	
	return ({
		weight: weight || -1,
		...QrValueMap(content?.qrcode),
		content:{
			on: content?.on || 1,
			bankNumber: content?.bankNumber || '',
			bankBin: content?.bankBin || '',
			bankNameAccount: content?.bankNameAccount || '',
			amount: content?.amount || 0,
			purpose: content?.purpose || ''
		}
	})
}

function getOptions() {
	let options = [];
	[...withPreOptions].forEach((v: any)=>options.push(v));
	[...withFrameOptions].forEach((v: any)=>options.push(v));
	return options;
}

/**
 *
 */
export const DataForm = withFormik({
	enableReinitialize: true,
	displayName: `QrCode`,
	mapPropsToValues,
	handleSubmit: function handleSubmit(){},
});

export default compose(
	withStyle,
	vietbankStyle,
	withQrUpload,
	withCrop,
	withMediaDeleting,
	withMedias(({})=>({ skip: !!__SERVER__, medias: Medias.instanse(), pageSize: 50, filter:{ type: 'qr' }})),
	withProps(({ uploadFile, medias, mediaDelete })=> ({
		frameOptions: getOptions(),
		items: medias.items || [],
		onUpload: onUpload(uploadFile),
		mediaDelete: (id: number) => mediaDelete(id).then(func(medias.refetch))
	})),
	withQrOptions,
	DataForm,
	// withProps(({ values })=> debug({ values })),
	withProps(({ onUpload, setFieldValue, handleSubmit, medias, values })=>
		({
			tabDefault: 'logo',
			data_text: getQrcodeData(values?.content),
			setDefault: function(){ setFieldValue('qr_default', true); handleSubmit(); },
			callback: async ({ file }) =>{
				return onUpload({ file })
					.then(func(medias.refetch))
					.catch(errorCatch)
			}
		}))
);