import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "dot_rounded",
 *   title = @Translation("DotRounded"),
 *   type = 13
 * )
 */
export class RendererDotRounded extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererDotRounded', {$options})
		super({...$options, type: 13 }, $qrcode)
	}
}
