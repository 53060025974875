import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "edge_cuts_smooth",
 *   title = @Translation("EdgeCuts Smooth"),
 *   type = 2
 * )
 */
export class RendererEdgeCutsSmooth extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererEdgeCutsSmooth', {$options})
		super({...$options, type: 2 }, $qrcode)
	}
}
