import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";
/**
 * @QrProvider(
 *   id = "pointed_mooth",
 *   title = @Translation("Pointed mooth"),
 *   type = 9
 * )
 */
export class RendererPointedMooth extends EdgeCutsBase{
	constructor($options: any = {}, $qrcode: QRCode) {
		debug('RendererPointedMooth', {$options})
		super({...$options, type: 9 }, $qrcode)
	}
}
